import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/4HMtC2kbfak"
    bibleGroupSrc="https://www.docdroid.net/dMaObZ2/bible-group-homework-10-4-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="https://www.bible.com/events/43208921"
  >
    <SEO title="Lions or Meerkats - Together is Better" />
  </Layout>
)

export default SermonPost
